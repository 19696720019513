import { motion, useAnimation } from "framer-motion";
import Menu from "../components/menu/Menu";
import React, { useEffect } from "react";
import MusicPlayer from "../components/MusicPlayer";
import Footer from "../components/footer/Footer";
import { useInView } from "react-intersection-observer";
import ImageLeftAnimation from "../components/animations/ImageLeftAnimation";
import ImageRightAnimation from "../components/animations/ImageRightAnimation";
import { useMediaQuery } from "@material-ui/core";
import theme from "../theme";

const KringliedPage = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const controls = useAnimation();
    const controls2 = useAnimation();
    const controls3 = useAnimation();
    const controls4 = useAnimation();

    const [ref, inView] = useInView();
    const [ref2, inView2] = useInView();
    const [ref3, inView3] = useInView();
    const [ref4, inView4] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (inView2) {
            controls2.start('visible');
        }
        if (inView3) {
            controls3.start('visible');
        }
        if (inView4) {
            controls4.start('visible');
        }

    }, [controls, controls2, controls3, controls4, inView, inView2, inView3, inView4]);

    return (
        <div className={"Page-root"}>
            <motion.section exit={{ opacity: 0 }}>
                <Menu />


                <section className={isMobile ? "KringliedPage-section1-mobile" : "KringliedPage-section1"}>
                    <h1 className={"Typography-red"}>Kringlied</h1>
                    <div className={isMobile ? "KringliedPage-section1-textBox-mobile" : "KringliedPage-section1-textBox"}>
                        <p className={"Typography-red"}>
                            Elke studentenvereniging heeft zijn eigen kringlied dat gezongen wordt op cantussen of andere gepaste gelegenheden.
                            Ook bij Diana is dit niet anders.
                            Het volledige kringlied kan je hier terugvinden, alsook op pagina 92 van de oude KVHV-codex,
                            op pagina 89 van de nieuwe KVHV-codex of op pagina 13 KVHV-codex (2017).
                        </p>
                        <p className={"Typography-red Typography-alinea"}>Het huidige kringlied is van de hand van Alex Vanheel, praeses uit ’82-’83.</p>
                        <p className={"Typography-red Typography-alinea"}>In 2022 is een aanpassing doorgevoerd, namelijk het toevoegen van een strofe voor al de nieuwe graduaten.</p>

                        <p className={"Typography-red Typography-alinea KringliedPage-section1-musictitel"}>Het kringlied op piano</p>
                        <MusicPlayer />

                        <div className={isMobile ? "KringliedPage-section1-lyricsBox-mobile" : "KringliedPage-section1-lyricsBox"}>
                            <div className={"KringliedPage-section1-topKringBox"}>
                                <div className={"KringliedPage-section1-alineaBox"}>
                                    <h4 className={"Typography-black Typography-italic"}>Allen</h4>
                                    <p className={"Typography-red Typography-bold"}>Wij zijn de club van de Dianen,</p>
                                    <p className={"Typography-red Typography-bold"}>Wij tappen bier uit alle kranen,</p>
                                    <p className={"Typography-red Typography-bold"}>Zijn wij nu hier,</p>
                                    <p className={"Typography-red Typography-bold"}>Ja, dan is het voor het bier</p>
                                    <p className={"Typography-red Typography-bold"}>Wij zijn de club van de Dianen.</p>
                                </div>

                                <div className={"KringliedPage-section1-alineaBox"}>
                                    <h4 className={"Typography-black Typography-italic"}>Industriële scheikunde</h4>
                                    <p className={"Typography-red Typography-bold"}>Zelf op ‘t labo staan wij te zuipen,</p>
                                    <p className={"Typography-red Typography-bold"}>Tot ons buret begint te druipen,</p>
                                    <p className={"Typography-red Typography-bold"}>Dan staan we op een rij,</p>
                                    <p className={"Typography-red Typography-bold"}>En pissen zij aan zij,</p>
                                    <p className={"Typography-red Typography-bold"}>In Erlenmeyers en in kuipen.</p>
                                </div>

                                <div className={"KringliedPage-section1-alineaBox"}>
                                    <h4 className={"Typography-black Typography-italic"}>Klinische scheikunde</h4>
                                    <p className={"Typography-red Typography-bold"}>Wij kunnen bier analyseren,</p>
                                    <p className={"Typography-red Typography-bold"}>Door het in ons maag te doen verteren.</p>
                                    <p className={"Typography-red Typography-bold"}>Zijn wij nu zat,</p>
                                    <p className={"Typography-red Typography-bold"}>Ja, dan is het door het nat</p>
                                    <p className={"Typography-red Typography-bold"}>En door de geur van ‘t hard studeren.</p>
                                </div>
                            </div>

                            <div className={"KringliedPage-section1-alineaBox"}>
                                <h4 className={"Typography-black Typography-italic"}>Informatica</h4>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Wij leren steeds maar programmeren,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Bier drinken moet men ons niet leren,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Kunnen wij iets niet,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Ja, dan zingen wij dit lied,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Laat ons nog wat pinten proberen.</p>
                            </div>

                            <div className={"KringliedPage-section1-alineaBox"}>
                                <h4 className={"Typography-black Typography-italic"}>Dieetleer</h4>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Wij kunnen eten produceren,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Dat g’in geen jaren kunt verteren.</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>If you come and eat,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>You’ll be cotsing on the street,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Maar dan steeds ter onzer ere.</p>
                            </div>


                            <div className={"KringliedPage-section1-alineaBox"}>
                                <h4 className={"Typography-black Typography-italic"}>Graduaten</h4>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Wij zijn een bende graduaten,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Op leeftijden zullen wij niet haten.</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>'s Middags aan de fles,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>En 's avonds naar de les.</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>'t Studentenleven kunnen wij niet laten.</p>
                            </div>

                            <div className={"KringliedPage-section1-alineaBox"}>
                                <h4 className={"Typography-black Typography-italic"}>Medisch secretariaat</h4>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Brieven typen moet men ons niet leren,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Want wij kunnen dactylograferen.</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Maar aan de andere kant,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Is het zeker zo plezant,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Met onze mooie benen te paraderen.</p>
                            </div>

                            <div className={"KringliedPage-section1-alineaBox"}>
                                <h4 className={"Typography-black Typography-italic"}>Artemis</h4>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Bij Diana hebben w’ons bewezen,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Als Artemis zijn we nu herrezen,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Nog éénmaal staan we hier,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Te bezwijken voor het bier,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Een kater hoeven wij niet meer te vrezen.</p>
                            </div>

                            <div className={"KringliedPage-section1-alineaBox"}>
                                <h4 className={"Typography-black Typography-italic"}>Allen</h4>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Heffen wij nu allen onze glazen,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Op het heil van deze roldersbazen.</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Wij houden vol,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>En gaan verder met de lol,</p>
                                <p className={isMobile ? "Typography-red Typography-bold" : "Typography-white Typography-bold"}>Tot we zitten met de lege glazen</p>
                            </div>

                        </div>

                    </div>


                </section>
                <section className={isMobile ? "KringliedPage-section2-mobile" : "KringliedPage-section2"}>
                    <div className={isMobile ? "KringliedPage-section1-textBox-mobile" : "KringliedPage-section2-textBox"}>
                        <h2 className={"Typography-white"}>Partituur</h2>
                        <p className={"Typography-white Typography-alinea"}>
                            Tijdens academiejaar 2008-2009 kregen de toenmalige schachten Stijn Arnauts en Robby Vanmarsenille
                            de ontgroeningsopdracht een schachtenlied te schrijven voor Diana.
                            Mede dankzij Stijn’s voortreffelijke kunnen met muziek verbaasden zij de gehele corona
                            op de ontgroeningscantus niet enkel met een prachtig, zelfgeschreven schachtenlied maar tevens met
                            <a rel={"noreferrer"} href={"https://www.facebook.com/watch/?v=10151139319339487"} target={"_blank"}> het kringlied dat door een orkest werd opgenomen</a>.
                            Eveneens zorgde deze opdracht voor partituren van zowel dit schachtenlied als het Diana-kringlied.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            Het spreekt uiteraard voor zich dat deze schachten met glans geslaagd waren voor hun opdracht.
                        </p>
                    </div>
                    <div className={isMobile ? "KringliedPage-section2-imageBox-mobile" : "KringliedPage-section2-imageBox"}>
                        <motion.img
                            alt="Diana kringlied"
                            src={"https://images.diana.be/diana_website/Kringlied.png"}
                            className="KringliedPage-partituur"
                            ref={ref}
                            animate={controls}
                            initial="hidden"
                            variants={ImageLeftAnimation}
                        />
                        <motion.img
                            alt="Diana schachtenlied"
                            src={"https://images.diana.be/diana_website/Schachtenlied.png"}
                            className="KringliedPage-partituur"
                            ref={ref2}
                            animate={controls2}
                            initial="hidden"
                            variants={ImageLeftAnimation}
                        />
                    </div>


                </section>

                <section className={isMobile ? "KringliedPage-section3-mobile" : "KringliedPage-section3"}>
                    <motion.div
                        ref={ref3}
                        animate={controls3}
                        initial="hidden"
                        variants={ImageLeftAnimation}
                        className={isMobile ? "KringliedPage-section3-songBox-mobile" : "KringliedPage-section3-songBox"}>
                        <div className={"KringliedPage-section3-alineaBox"}>
                            <p className={"Typography-red Typography-bold"}>Vier regastjes zag men ter leësse gaan</p>
                            <p className={"Typography-red Typography-bold"}>Al wankelend op hunne bene.</p>
                            <p className={"Typography-red Typography-bold"}>Zij gingen naar elke nachtaktiviteit</p>
                            <p className={"Typography-red Typography-bold"}>En waren DIANA’s stoerste leden.</p>
                        </div>

                        <div className={"KringliedPage-section3-refreinBox"}>
                            <p className={"Typography-red Typography-bold Typography-italic"}>Pipetten, buretten, spoel uit!</p>
                            <p className={"Typography-red Typography-bold Typography-italic"}>Tikmachinen, logische blokken, programmeer!</p>
                            <p className={"Typography-red Typography-bold Typography-italic"}>En waren DIANA’s stoerste leden.</p>
                        </div>

                        <div className={"KringliedPage-section3-alineaBox"}>
                            <p className={"Typography-red Typography-bold"}>Die eerste en was er een laäborant</p>
                            <p className={"Typography-red Typography-bold"}>En kon titreren als er geen ene.</p>
                            <p className={"Typography-red Typography-bold"}>Hij had steeds veel alkohol al in zijne bloed</p>
                            <p className={"Typography-red Typography-bold"}>En nooit tijd om zijn lesse te lere.</p>
                        </div>

                        <div className={"KringliedPage-section3-alineaBox"}>
                            <p className={"Typography-red Typography-bold"}>Die tweede en was er een diëtetist</p>
                            <p className={"Typography-red Typography-bold"}>En wist er alles van calorieën.</p>
                            <p className={"Typography-red Typography-bold"}>Hij berekende wat hij per dag nodig had</p>
                            <p className={"Typography-red Typography-bold"}>En dronk bier om zijn behoefte te dekken.</p>
                        </div>
                        <div className={"KringliedPage-section3-alineaBox"}>
                            <p className={"Typography-red Typography-bold"}>Die derde en was er een computerfan</p>
                            <p className={"Typography-red Typography-bold"}>En kon het snel brein informeren.</p>
                            <p className={"Typography-red Typography-bold"}>Hij ging maar liefst iedere avond op zwier</p>
                            <p className={"Typography-red Typography-bold"}>Om bierkaartjes te perforeren.</p>
                        </div>
                        <div className={"KringliedPage-section3-alineaBox"}>
                            <p className={"Typography-red Typography-bold"}>Die vierde en was er een secretares</p>
                            <p className={"Typography-red Typography-bold"}>En kon typen aan hinderd per ure.</p>
                            <p className={"Typography-red Typography-bold"}>Zij hing veel studentjes al aän het lijf</p>
                            <p className={"Typography-red Typography-bold"}>Op elke fuif kreeg zij haar kure.</p>
                        </div>
                        <div className={"KringliedPage-section3-alineaBox"}>
                            <p className={"Typography-red Typography-bold"}>Al waren zij haast elke daäg zat</p>
                            <p className={"Typography-red Typography-bold"}>Toch mag men er fier op wezen.</p>
                            <p className={"Typography-red Typography-bold"}>Zij bewezen wat een student zoal kan</p>
                            <p className={"Typography-red Typography-bold"}>En hielden DIANA’s naam in ere.</p>
                        </div>
                    </motion.div>
                    <div className={isMobile ? "KringliedPage-section3-textBox-mobile" : "KringliedPage-section3-textBox"}>
                        <h2 className={"Typography-red"}>Oud kringlied</h2>
                        <p className={"Typography-red Typography-alinea"}>
                            In een ver verleden beschikte Diana over een ander kringlied.
                            De precieze jaartallen waarop dit kringlied werd geschreven en wanneer het werd afgeschaft zijn helaas verloren gegaan,
                            maar het is wel geweten dat de schrijver een zekere Guido was uit het tweede jaar Dieet.
                            In 1978 was dit lied alvast het kringlied van Diana.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Dit lied werd gezongen op de melodie van “De 4 weverkens”.
                        </p>

                        {!isMobile ?

                            <motion.div
                                className={"KringliedPage-section3-imageBox"}
                                ref={ref4}
                                animate={controls4}
                                initial="hidden"
                                variants={ImageRightAnimation}
                            >
                                <img alt="Arnaud dat het kringlied zingt" src={"https://images.diana.be/diana_website/Singing.jpg"} className="KringliedPage-section3-image" />
                                <p className={"Typography-black KringliedPage-section3-subText"}>Stijn Arnauts, schreef samen met Robby Vanmarsenille Diana’s schachtenlied.</p>
                            </motion.div> : null}

                    </div>

                </section>

            </motion.section>
            <Footer color={"primary"} />
        </div>
    )
}
export default KringliedPage;
