import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import React from "react";
import { AnimatePresence } from "framer-motion";
import KringliedPage from "./pages/KringliedPage";
import Geschiedenispage from "./pages/GeschiedenisPage";
import ContactPage from "./pages/ContactPage";
import StatutenPage from "./pages/StatutenPage";
import PraesidiaPage from "./pages/PraesidiaPage";
import AluminuPage from "./pages/AluminuPage";
import ProseniorenPage from './pages/ProseniorenPage';
import Sponsers from "./pages/Sponsers";
import ScrollToTop from "./components/ScrollToTop";
import GDPRPage from "./pages/GDPRPage";
import InternReglementPage from "./pages/InternReglementPage";
import { createBrowserHistory } from "history";
import ReactGA from 'react-ga';
import ActivitiesPage from "./pages/ActivitiesPage";
import EerstejaarswerkingPage from "./pages/EerstejaarswerkingPage";

/**
 * This list is used for the activities page internal linking
 */
const activities = [
       "activiteiten",
       "strandcantus",
       "dianalympische",
       "lustrum",
       "nvdj",
       "sweettropical",
       "jagerbombrecord"
]

const scroll = () => {
       let path = window.location.pathname;
       let route = path.substring(path.lastIndexOf("/") + 1);
       if (activities.includes(route)) {
              let element = document.getElementById(route);
              if (element) {
                     // Will scroll smoothly to the top of the next section
                     element.scrollIntoView({ behavior: 'smooth' });
              }
              return null;
       }
       else return (<ActivitiesPage />);
}

export interface Props {
       isFirstMount: boolean;
}

const Routes = ({ isFirstMount }: Props) => {
       const location = useLocation();

       const history = createBrowserHistory();

       // used for Google Analytics tracking
       history.listen((location) => {
              ReactGA.initialize('UA-144042472-3');
              ReactGA.set({ page: location.pathname });
              ReactGA.pageview(location.pathname)
       }
       );

       //All the routes for pages
       return (
              <AnimatePresence exitBeforeEnter>
                     <ScrollToTop />

                     <Switch location={location} key={location.pathname}>
                            {/*routes reachable for everyone*/}
                            <Route path="/" exact
                                   component={(props: any) => (
                                          <HomePage isFirstMount={isFirstMount} />
                                   )}
                            />
                            <Route path="/home"
                                   component={(props: any) => (
                                          <HomePage isFirstMount={isFirstMount} />
                                   )}
                            />


                            <Route path={"/praesidium"}
                                   component={(props: any) => (<PraesidiaPage />)}
                            />
                            <Route path={"/alumia/:year"}
                                   component={(props: any) => (<AluminuPage props={props} />)}
                            />
                            <Route path={"/prosenioren"}
                                   component={(props: any) => (<ProseniorenPage />)}
                            />


                            <Route path={"/kringlied"}
                                   component={(props: any) => (<KringliedPage />)}
                            />

                            <Route path={"/sponsers"}
                                   component={(props: any) => (<Sponsers />)}
                            />

                            <Route path={"/geschiedenis"}
                                   component={(props: any) => (<Geschiedenispage />)}
                            />
                            <Route path={"/statuten"}
                                   component={(props: any) => (<StatutenPage />)}
                            />
                            <Route path={"/intern-reglement"}
                                   component={(props: any) => (<InternReglementPage />)}
                            />
                            <Route path={"/gdpr"}
                                   component={(props: any) => (<GDPRPage />)}
                            />
                            <Route path={"/strandcantus"}
                                   component={(props: any) => (<ActivitiesPage />)}
                            />
                            <Route path={"/dianalympische"}
                                   component={(props: any) => (<ActivitiesPage />)}
                            />
                            <Route path={"/lustrum"}
                                   component={(props: any) => (<ActivitiesPage />)}
                            />
                            <Route path={"/nvdj"}
                                   component={(props: any) => (<ActivitiesPage />)}
                            />
                            <Route path={"/sweettropical"}
                                   component={(props: any) => (<ActivitiesPage />)}
                            />
                            <Route path={"/jagerbombrecord"}
                                   component={(props: any) => (<ActivitiesPage />)}
                            />
                            <Route path={"/eerstejaarswerking"}
                                   component={(props: any) => (<EerstejaarswerkingPage />)}
                            />
                            <Route path={"/eerstejaarsweekend"}
                                   component={(props: any) => (<EerstejaarswerkingPage />)}
                            />
                            <Route path={"/meterpeter"}
                                   component={(props: any) => (<EerstejaarswerkingPage />)}
                            />
                            <Route path={"/examenwiki"}
                                   component={() => {
                                          var anchor = document.createElement('a');
                                          anchor.href = 'https://examenwiki.diana.be/';
                                          anchor.click();
                                          return null
                                   }}
                            />
                            <Route path={"/examenrooster"}
                                   component={() => {
                                          var anchor = document.createElement('a');
                                          anchor.href = 'https://examenrooster.diana.be/';
                                          anchor.click();
                                          return null;
                                   }}
                            />
                            <Route path={"/lidkaarten"}
                                   component={() => {
                                          var anchor = document.createElement('a');
                                          anchor.href = 'https://lidkaarten.diana.be/';
                                          anchor.target = "_blank";
                                          anchor.click();
                                          return <Redirect to="/" />
                                   }}
                            />
                            <Route path={"/contact"}
                                   component={(props: any) => (<ContactPage />)}
                            />
                            <Route path={"/webshop"}
                                   component={() => {
                                          var anchor = document.createElement('a');
                                          anchor.target = "_blank"
                                          anchor.href = 'https://dianaleuven.sumupstore.com/';
                                          anchor.click();
                                          return <Redirect to="/" />
                                   }}
                            />

                            {/*default if no match with previous routes.*/}
                            <Route path={"*"} component={(props: any) => <Redirect to={"/home"} />} />
                     </Switch>
              </AnimatePresence>
       );
};

export default Routes;
