 import {AnimatePresence, AnimateSharedLayout, motion, useAnimation} from 'framer-motion';
 import Menu from '../components/menu/Menu';
 import React, {Props, useCallback, useEffect, useState} from 'react';
 import {GetCurrentYear, GetPraesidia, GetPraesidiaFromYear} from '../api/api';
 import {PraesidiaObject} from '../objects/PraesidiaObject';
 import {useMediaQuery} from "@material-ui/core";
 import theme from "../theme";
 import {useInView} from "react-intersection-observer";
 import Footer from "../components/footer/Footer";
import { useParams } from 'react-router-dom';

 const PraesidiaPage = ({props}: any) => {
     const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
     const [praesidia, setPraesidia] = useState<PraesidiaObject[]>();
     const [selectedBestuurslid, setSelectedBestuurslid] = useState<PraesidiaObject | null>(null);
     const [currentYear, setCurrentYear] = useState("")
     const getData = useCallback(async () => {
         try {
      
                const response = await GetPraesidia()
                setPraesidia(response.data);
                const response2 = await GetCurrentYear()
                setCurrentYear(response2.data)
       
           
         } catch (e) {
         }
     }, []);

     const [ref, inView] = useInView();
     const controls = useAnimation();

     useEffect(() => {
         if (inView) {
             controls.start('visible');
         }
     })


     useEffect(() => {
         getData();
     }, [getData])

     return (
         <div className={"Page-root"}>
             <motion.section exit={{opacity: 0}}>
                 <Menu/>
                 <div className={"PraesidiaPage-section1"}>
                     <div className={isMobile? "PraesidiaPage-titleBox-mobile": "PraesidiaPage-titleBox"}>
                         <h1 className={"Typography-red"}>Onze Sponsors</h1>
                     </div>

        
                 </div>


             </motion.section>
             <motion.section className={isMobile? "PraesidiaPage-section2-mobile" : "PraesidiaPage-section2"}>
             <div className="cards-wrapper">
                                <div className="card2-grid-space">
            <a className="card2" href="https://images.diana.be/lidkaarten/archief.jpg" target="_blank"
               style={{backgroundImage: "url(https://images.diana.be/lidkaarten/archief.jpg)"}}>
                <div>
                    <h1>Diana Facavonden @Archief</h1>
               
                </div>
            </a>
        </div>




        <div className="card2-grid-space">
            <a className="card2" href="https://www.sushileuven.be/index.html" target="_blank"
              style={{backgroundImage:" url('https://images.diana.be/lidkaarten/sushileuven.png')"}}>
                <div>
                    <h1>Sushi Leuven</h1>
               
                </div>
            </a>
        </div>




        <div className="card2-grid-space">
            <a className="card2" href="https://deplantrekkers.com/" target="_blank"
              style={{backgroundImage:"url('https://images.diana.be/lidkaarten/plantrekkers.png')"}}>
                <div>
                    <h1>Plantrekkers</h1>
                </div>
            </a>
        </div>

        <div className="card2-grid-space">
            <a className="card2" href="https://www.namastetandoori.be/" target="_blank"
                style={{backgroundImage:"url('https://images.diana.be/lidkaarten/namaste.png')"}}>
                <div>
                    <h1>Namaste</h1>
                </div>
            </a>
        </div>

        <div className="card2-grid-space">
            <a className="card2" href="https://www.lavinialeuven.be/" target="_blank"
               style={{backgroundImage:" url('https://images.diana.be/lidkaarten/lavinialeuven.jpg')"}}>
                <div>
                    <h1>Lavinia</h1>
                </div>
            </a>
        </div>

        <div className="card2-grid-space">
            <a className="card2" href="https://www.facebook.com/profile.php?id=100063717755957" target="_blank"
              style={{backgroundImage:" url('https://images.diana.be/lidkaarten/chaosleuven.jpg')"}}>
                <div>
                    <h1>Chaos Snooker</h1>
                </div>
            </a>
        </div>


        <div className="card2-grid-space">
            <a className="card2" href="https://jfix.be/index.html" target="_blank"
               style={{backgroundImage:" url('https://images.diana.be/lidkaarten/jfix.png')"}}>
                <div>
                    <h1>JFIX</h1>
                </div>
            </a>
        </div>

    
        </div>
             </motion.section>
             <Footer color={"paper"}/>
         </div>
     )
 }
 export default PraesidiaPage
