import palette from "./palette";
import { createTheme, Theme } from "@material-ui/core";

//Not necesarrily used anymore. interesting for use with Material-UI so leaving it here
const theme: Theme = createTheme(
    {
        palette,
    },
);

export default theme;
