import { motion, useAnimation } from "framer-motion";
import Menu from "../components/menu/Menu";
import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import { useInView } from "react-intersection-observer";
import ImageRightAnimation from "../components/animations/ImageRightAnimation";
import { useMediaQuery } from "@material-ui/core";
import theme from "../theme";
import ImageLeftAnimation from "../components/animations/ImageLeftAnimation";
import ImageRightWithRotateAnimation from "../components/animations/ImageRightWithRotateAnimation";
import { ProseniorObject } from "../objects/ProseniorObject";
import { GetCurrentYear, GetPraesidia, GetProsenioren } from "../api/api";
import { PraesidiumCard } from "../components/praesidiumPage/PraesidiumCard";
import { Link } from "react-router-dom";

const GeschiedenisPage = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const controls = useAnimation();
    const controls2 = useAnimation();
    const controls3 = useAnimation();
    const controls4 = useAnimation();
    const controls5 = useAnimation();
    const controls6 = useAnimation();
    const controls7 = useAnimation();
    const controls8 = useAnimation();
    const controls9 = useAnimation();
    const controls10 = useAnimation();

    const [ref, inView] = useInView();
    const [ref2, inView2] = useInView();
    const [ref3, inView3] = useInView();
    const [ref4, inView4] = useInView();
    const [ref5, inView5] = useInView();
    const [ref6, inView6] = useInView();
    const [ref7, inView7] = useInView();
    const [ref8, inView8] = useInView();
    const [ref9, inView9] = useInView();
    const [ref10, inView10] = useInView();

    const [prosenioren, setProsenioren] = useState<ProseniorObject[]>();

    const getData = useCallback(async () => {
        try {
            const response = await GetProsenioren()
            setProsenioren(response.data);
        } catch (e) {
        }
    }, []);

    useEffect(() => {
        getData()
        if (inView) {
            controls.start('visible');
        }
        if (inView2) {
            controls2.start('visible');
        }
        if (inView3) {
            controls3.start('visible');
        }
        if (inView4) {
            controls4.start('visible');
        }
        if (inView5) {
            controls5.start('visible');
        }
        if (inView6) {
            controls6.start('visible');
        }
        if (inView7) {
            controls7.start('visible');
        }
        if (inView8) {
            controls8.start('visible');
        }
        if (inView9) {
            controls9.start('visible');
        }
        if (inView10) {
            controls10.start('visible');
        }
    }, [controls, controls2, controls3, controls4, controls5, controls6, controls7, controls8, controls9, controls10,
        inView, inView2, inView3, inView4, inView5, inView6, inView7, inView8, inView9, inView10]);

    return (
        <div className={"Page-root"}>
            <motion.section exit={{ opacity: 0 }}>
                <Menu />
                <section className={isMobile ? "GeschiedenisPage-section1-mobile" : "GeschiedenisPage-section1"}>
                    <div className={isMobile ? "GeschiedenisPage-textBox-left1-mobile" : "GeschiedenisPage-textBox-left1"}>
                        <h1 className={"Typography-red"}>Geschiedenis</h1>

                        {/*Dit is interessante info om mogelijks toe te voegen. Hallo als je dit leest trouwens ;) .*/}

                        {/*<p className={"Typography-white Typography-alinea"}>*/}
                        {/*    1.2. De vereniging wordt opgericht door volgende personen:*/}

                        {/*    <ul className={"StatutenPage-ul"}>*/}
                        {/*        <li className={"StatutenPage-li"}>Lars Leenders</li>*/}
                        {/*        <li className={"StatutenPage-li"}>Dante Schroyens</li>*/}
                        {/*        <li className={"StatutenPage-li"}>Evi Vandenborre</li>*/}
                        {/*        <li className={"StatutenPage-li"}>Gunther Piron</li>*/}
                        {/*        <li className={"StatutenPage-li"}>Linn Vande Populiere</li>*/}
                        {/*    </ul>*/}
                        {/*</p>*/}

                        <p className={"Typography-red"}>
                            Diana werd opgericht in 1960 door Jan Depuydt,
                            student van de toenmalige Regaschool te Leuven.
                            De naam Diana is afkomstig van de samentrekking van diëtisten en analisten,
                            de twee hoofdzakelijke studiegebieden die destijds aangeboden werden.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Door de jaren heen is Diana uitgegroeid tot een vereniging die zich niet alleen engageert voor de feestelijke kant van het studentenleven,
                            maar ook voor vele andere facetten van het leven waar de student van vandaag mee in contact komt.
                            Zo bieden wij ook sport- en cultuuractiviteiten aan,
                            organiseren wij jaarlijks een galabal en verzorgen wij ook een warm onthaal voor eerstejaarsstudenten aan ons departement.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Bij al onze activiteiten staat echter één thema centraal:
                            ervoor zorgen dat de student zich thuis voelt in Leuven en aan ons departement!
                        </p>

                    </div>


                    <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                        <motion.img
                            alt="Diana schachtenlied"
                            src={"https://images.diana.be/diana_website/Geschiedenis1.jpg"}
                            className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                            ref={ref}
                            animate={controls}
                            initial="hidden"
                            variants={ImageRightAnimation}
                        />
                        Wil je meer foto's zien <a href="https://drive.google.com/drive/folders/1wuFZBHDh5c0b4Q-aeN2daa5o0tSVvBkG?usp=share_link"> Klik hier op</a>
                    </div>

                </section>

                <section className={isMobile ? "GeschiedenisPage-section2-mobile" : "GeschiedenisPage-section2"}>
                    <div className={isMobile ? "GeschiedenisPage-imageBox-left-mobile" : "GeschiedenisPage-imageBox-left"}>
                        <motion.img
                            alt="Diana Schild"
                            src={"https://images.diana.be/diana_website/Schild.svg"}
                            className={isMobile ? "GeschiedenisPage-schild-mobile" : "GeschiedenisPage-schild"}
                            ref={ref2}
                            animate={controls2}
                            initial="hidden"
                            variants={ImageLeftAnimation}
                        />
                    </div>
                    <div className={isMobile ? "GeschiedenisPage-textBox-right-mobile" : "GeschiedenisPage-textBox-right"}>
                        <h2 className={"Typography-white"}>De godin Diana</h2>

                        <p className={"Typography-white"}>
                            Degenen met wat kennis over de Romeinse mythologie zullen alvast bekend zijn met de Romeinse godin Diana,
                            die vereerd werd als godin van de jacht en de nacht.
                            Een ludieke knipoog naar de student die de bloemetjes buiten zet ‘s nachts
                            en op jacht is naar de liefde, dachten we zo.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            Vele gebruiken en tradities bij Diana zijn dan ook gebaseerd op de gelijknamige godin.
                            Zo organiseert studentenvereniging Diana jaarlijks de beruchte TD “Nacht van de Jacht”,
                            die veel studenten ongetwijfeld bekend in de oren klinkt.
                        </p>

                        <h2 className={"Typography-white GeschiedenisPage-spacing"}>Het clubschild</h2>

                        <p className={"Typography-white"}>
                            Een kenmerk dat je ongetwijfeld al is opgevallen is het clubschild van Diana,
                            dat op elk van onze evenementen opduikt alsook op de t-shirts en pullen die onze leden dragen.
                            Er schuilt natuurlijk enige betekenis achter de symbolen op ons schild.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            Het linkerdeel illustreert een boog, pijlenkoker en de maan
                            en is hiermee een rechtstreekse verwijzing
                            naar de Romeinse godin Diana en haar symboliek als godin van de nacht en de jacht.
                        </p>
                        <p className={"Typography-white Typography-alinea"}>
                            Op het rechterdeel van het schild vinden we een monogram terug met de letters
                            V, C en F dat staat voor de Latijnse spreuk Vivat, Crescat, Floreat – oftewel “Hij leve, groeie, bloeie”.
                            Dit monogram is doorvlochten met de D van Diana en is vergezeld van een faculteitsteken,
                            om aan te duiden dat de kring Diana nog actief is.
                        </p>
                    </div>
                </section>



                <section className={isMobile ? "GeschiedenisPage-section3-mobile" : "GeschiedenisPage-section3"}>
                    <div className={isMobile ? "GeschiedenisPage-textBox-left-mobile" : "GeschiedenisPage-textBox-left"}>
                        <h2 className={"Typography-red"}>Diana en haar departement</h2>

                        <p className={"Typography-red"}>
                            Uiteraard heeft de hogeschool waar Diana haar thuis heeft in de loop der tijden een hele metamorfose ondergaan.
                            Van 1960 tot nu zijn er verscheidene veranderingen geweest in het onderwijs,
                            waarvan de oprichting van de Katholieke Hogeschool Leuven in 1995 wellicht wel het meeste invloed heeft gehad op Diana.

                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Tot 1995 was Diana verbonden aan de zogenaamde Regaschool,
                            tot dat moment een onafhankelijke hogeschool. In 1995 wordt de Katholieke Hogeschool Leuven opgericht,
                            en bundelen vijf departementen in Leuven en Heverlee hun krachten.
                            De Regaschool wordt vanaf nu het departement Rega van de KHLeuven genoemd.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Van eind jaren 1998 tot 2008 had Diana bovendien haar facbar vlakbij het departement Rega,
                            in de J. P. Minckelersstraat 98.
                            Hoewel Diana’s facavond toen ook steeds op zondagavond was en de facbar enkel dan gebruikt werd,
                            was het poolcafé waaronder de facbar lag elke dag open.
                            Het was dan ook een populaire trekpleister voor vele studenten om tijdens hun springuren wat verfrissing te zoeken en de dorst te lessen.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            De KHLeuven zit natuurlijk niet stil, en besluit de departement Rega en Verpleeg- en Vroedkunde (V&V) te fusioneren tot het nieuwe departement Gezondheidszorg en Technologie.
                            Hierbij nemen de studenten hun intrek in een gloednieuwe campus aan het universitair ziekenhuis.
                            Eén van de aula’s in dit nieuwe gebouw werd Rega gedoopt als knipoog naar de voorgeschiedenis van Diana’s oude departement.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Sinds 2012 is Diana spijtig genoeg wel haar richting MMA verloren,
                            dit is een andere richting geworden bij het departement ECHO.
                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Sinds 2014-2015 is de KHLeuven van naam veranderd en gefuseerd met de hogescholen in Limburg.
                            Deze fusie gaat verder onder de naam UCLL (University Colleges Leuven-Limburg).
                            Bovendien is er ook voor de informatici onder ons een nieuwe campus opgericht, campus Proximus (Management en Technologie),
                            waarna ook de campus Gezondheidszorg en Technologie een naamsverandering heeft ondergaan.
                            Deze heet vanaf dit moment Gezondheidszorg en Chemie.


                        </p>
                    </div>
                    <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                        <motion.img
                            alt="Diana schachtenlied"
                            src={"https://images.diana.be/diana_website/Rega.jpg"}
                            className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                            ref={ref3}
                            animate={controls3}
                            initial="hidden"
                            variants={ImageRightAnimation}
                        />
                        <div className={isMobile ? "GeschiedenisPage-imageBox-subBox-mobile" : "GeschiedenisPage-imageBox-subBox"} >
                            <motion.img
                                alt="Diana schachtenlied"
                                src={"https://images.diana.be/diana_website/GHB.jpg"}
                                className={isMobile ? "GeschiedenisPage-subBox-image-mobile" : "GeschiedenisPage-subBox-image"}
                                ref={ref4}
                                animate={controls4}
                                initial="hidden"
                                variants={ImageRightAnimation}
                            />
                            <motion.img
                                alt="Diana schachtenlied"
                                src={"https://images.diana.be/diana_website/Prox.jpg"}
                                className={isMobile ? "GeschiedenisPage-subBox-image2-mobile" : "GeschiedenisPage-subBox-image2"}
                                ref={ref4}
                                animate={controls4}
                                initial="hidden"
                                variants={ImageRightWithRotateAnimation}
                            />
                        </div>
                        <p className={isMobile ? "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right-mobile" : "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right"}>Het departement Rega, campus proximus en campus Gasthuisberg</p>

                    </div>
                </section>


                <section className={isMobile ? "GeschiedenisPage-section4-mobile" : "GeschiedenisPage-section4"}>
                    <div className={isMobile ? "GeschiedenisPage-section4-box-mobile" : "GeschiedenisPage-section4-box"}>
                        <div className={isMobile ? "GeschiedenisPage-imageBox-left-mobile" : "GeschiedenisPage-imageBox-left"}>
                            <motion.img
                                alt="De Pocket"
                                src={"https://images.diana.be/diana_website/Pocket.jpeg"}
                                className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                                ref={ref5}
                                animate={controls5}
                                initial="hidden"
                                variants={ImageLeftAnimation}
                            />
                            <p className={isMobile ? "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-left-mobile" : "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-left"}>Diana’s facbar op de benedenverdieping van The Pocket,</p>
                            <p className={isMobile ? "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-left-mobile" : "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-left"}>  ergens tussen 1999 en februari 2008.</p>
                        </div>
                        <div className={isMobile ? "GeschiedenisPage-textBox-right-mobile" : "GeschiedenisPage-textBox-right"}>
                            <h2 className={"Typography-white"}>Diana en haar facbar</h2>

                            <p className={"Typography-white"}>
                                In haar rijkgevulde geschiedenis heeft Diana haar thuis gekend in vele kroegen en herbergen.
                                Helaas is de informatie hieromtrent niet altijd even goed bewaard gebleven,
                                waardoor we vooral van het recente verleden weten waar Diana haar facbar had.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                Wat met zekerheid vaststaat is dat Diana voor 1999 haar thuis vond in onder andere café Montmartre en De Vagant.
                                Deze laatste is ondertussen gesloten en was gelegen aan de Vismarkt.
                            </p>

                            <p className={"Typography-white Typography-alinea"}>
                                Van 1999 tot 2008 kende Diana haar vaste thuis in The Pocket, een pool- en snookercafé gelegen in de J.P. Minckelersstraat 98.
                                De poolzaal en -tafels waren boven gevestigd, het gelijkvloers was de fakbar waar elke zondag het gerstenat vlijtig vloeide. De prijzen werden bewust laag gehouden,
                                break-even draaien was de norm – het doel was tenslotte om de studenten van het departement Rega een goedkope en gezellige uitgaansmogelijkheid te bezorgen.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                Achteraan de fakbar was een zaal gelegen die Diana eveneens ter beschikking had,
                                en die we tegen een spotgoedkope prijs verhuurden aan andere studentenkringen in Leuven
                                zodat iedereen de kans kreeg om te cantussen tegen een aanvaardbare prijs
                                (cantussen is en blijft namelijk een kostelijke aangelegenheid voor de organiserende kring, ook de dag van vandaag).

                            </p>
                        </div>
                    </div>
                    <div className={isMobile ? "GeschiedenisPage-section4-box-mobile2" : "GeschiedenisPage-section4-box"}>

                        <div className={isMobile ? "GeschiedenisPage-textBox-left-mobile" : "GeschiedenisPage-textBox-left"}>
                            <p className={"Typography-white Typography-alinea"}>
                                In februari 2008 nam Diana haar intrek op de bovenverdieping van The Pocket,
                                waar zij tevens het interieur stevig onder handen nam en volledig in het rood schilderde met zwarte accenten.
                                Ook hier was achteraan een zaal ter beschikking, die ook toen regelmatig gebruikt werd door andere kringen.
                                Het poolcafé verhuisde op zijn beurt naar het gelijkvloers, dat tevens ook volledig vernieuwd werd.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                Helaas was niet alles rozengeur en manenschijn.
                                Door relatie met de toenmalige eigenaar liep niet bepaald soepel,
                                en in november 2008 kwamen de geschillen tot een dusdanig hoogtepunt dat Diana geen andere uitweg zag dan de Pocket te verlaten.
                                Het was tijdens de legendarische Halloween-fakavond op 2 november dat er besloten werd om de activiteiten onmiddellijk stop te zetten.
                                Tot in de vroege ochtend was het toenmalige bestuur druk in de weer met het afbreken en verzamelen van Diana’s bezittingen,
                                en deze naar het departement G&T aan Gasthuisberg te vervoeren.
                                Bart Sallets, cantor het jaar voordien, stelde hiervoor zijn camionette ter beschikking –
                                iets dat hem bovendien nog altijd in dank wordt afgenomen.
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                Uiteraard bleef Diana na het vertrek uit The Pocket niet in zak en as zitten,
                                en begonnen we steevast aan het zoeken naar een nieuwe fakbar.
                                Het spreekt voor zich dat het geen eenvoudige taak zou zijn om een locatie met hetzelfde potentieel en dezelfde vrijheid te vinden die The Pocket ons bood.
                                In een tijd van immer stijgende drankprijzen is het bovendien geen sinecure om studenten dezelfde voordelen te bieden dan we tot op dat moment gewend waren.
                            </p>
                        </div>
                        <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>

                            <motion.img
                                alt="Cantus zaal van de Pocket"
                                src={"https://images.diana.be/diana_website/CantusPocket.jpg"}
                                className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                                ref={ref6}
                                animate={controls6}
                                initial="hidden"
                                variants={ImageRightAnimation}
                            />
                            <p className={isMobile ? "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right-mobile" : "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right"}>Een cantus in de zaal achter de Pocket.</p>

                        </div>
                    </div>

                    <div className={isMobile ? "GeschiedenisPage-section4-box-mobile" : "GeschiedenisPage-section4-box"}>

                        <div className={isMobile ? "GeschiedenisPage-imageBox-left-mobile" : "GeschiedenisPage-imageBox-left"}>
                            <motion.img
                                alt="Mephist'O leuven"
                                src={"https://images.diana.be/diana_website/Mephisto.png"}
                                className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                                ref={ref7}
                                animate={controls7}
                                initial="hidden"
                                variants={ImageLeftAnimation}
                            />
                            <p className={isMobile ? "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-left-mobile" : "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-left"}>Mephist’O, op de Oude Markt te Leuven.</p>

                        </div>
                        <div className={isMobile ? "GeschiedenisPage-textBox-right-mobile" : "GeschiedenisPage-textBox-right"}>
                            <p className={"Typography-white Typography-alinea"}>
                                Na overleg met verscheidene cafés in Leuven werd de reddende oplossing geboden door Mephist’O,
                                toen een beginnende zaak op de Oude Markt gelegen in het pand van het voormalige café De Affiche.
                                De ideale ligging en de vele mogelijkheden die het stadscentrum ons bood,
                                de overeengekomen promoties die onze leden zouden genieten en de bruisende dynamiek die het café uitstraalde gaven de doorslag.
                                Nauwelijks een week na het vertrek uit The Pocket had Diana haar nieuwe thuis gevonden,
                                en op zondag 9 november werd Mephist’O feestelijk ingehuldigd tot nieuwe fakbar van Diana!
                            </p>
                            <p className={"Typography-white Typography-alinea"}>
                                Maar spijtig genoeg ging de Mephist’O toe en moest Diana opzoek naar een nieuw facbar
                                en deze hebben we gevonden in de Maxim’O!
                                Verscheidene jaren heeft Diana hier met veel plezier fakavonden georganiseerd, tot de corona-pandemie roet in het eten smeet.
                                Na meer dan een jaar slechts in beperkte maten te mogen openen ten gevolge van corona maatregelen heeft de Maxim'O de deuren definitief moeten sluiten.
                            </p>

                            <p className={"Typography-white Typography-alinea"}>
                                Nogmaals zoekend naar een nieuwe facbar zijn we in 2021 terechtgekomen bij de Popclub boven de Rumba.
                                De centrale ligging en naamsbekendeheid van deze feestzaal bieden een enorm spectrum aan nieuwe mogelijkheden
                                waarmee team facbar gegarandeerd top facavonden had georganiseerd! Jammer genoeg moesten onze paden scheiden en waren we terug op zoektocht!
                            </p>

                            <p className={"Typography-white Typography-alinea"}>
                                In 2022 zijn we beland in het famouze T'Archief. Waar elke zondag onze bekende fac zich schuilhoud.
                                Elke week is er een ander thema, maar telkens meer plezier! Kijk goed uit voor onze promo's want deze zijn de beste van Leuven!
                            </p>

                        </div>
                    </div>
                </section>

            </motion.section>
            <Footer color={"primary"} />
        </div>
    )
}
export default GeschiedenisPage;
