import { AnimatePresence, AnimateSharedLayout, motion, useAnimation } from 'framer-motion';
import Menu from '../components/menu/Menu';
import React, { Props, useCallback, useEffect, useState } from 'react';
import { GetCurrentYear, GetPraesidia, GetPraesidiaFromYear } from '../api/api';
import { PraesidiaObject } from '../objects/PraesidiaObject';
import { useMediaQuery } from "@material-ui/core";
import theme from "../theme";
import { useInView } from "react-intersection-observer";
import Footer from "../components/footer/Footer";
import { useParams } from 'react-router-dom';
import ImageRightAnimation from "../components/animations/ImageRightAnimation";
import ImageLeftAnimation from "../components/animations/ImageLeftAnimation";
import ImageRightWithRotateAnimation from "../components/animations/ImageRightWithRotateAnimation";

/**
 * This list is used for the page's internal linking
 */
const subjects = [
    "eerstejaars",
    "eerstejaarswerking",
    "eerstejaarsweekend",
    "meterpeter"
]

const PraesidiaPage = ({ props }: any) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [praesidia, setPraesidia] = useState<PraesidiaObject[]>();
    const [selectedBestuurslid, setSelectedBestuurslid] = useState<PraesidiaObject | null>(null);
    const [currentYear, setCurrentYear] = useState("")
    const getData = useCallback(async () => {
        try {

            const response = await GetPraesidia()
            setPraesidia(response.data);
            const response2 = await GetCurrentYear()
            setCurrentYear(response2.data)


        } catch (e) {
        }
    }, []);

    const [ref, inView] = useInView();
    const [ref2, inView2] = useInView();
    const [ref3, inView3] = useInView();
    const [ref4, inView4] = useInView();
    const [ref5, inView5] = useInView();

    const controls = useAnimation();
    const controls2 = useAnimation();
    const controls3 = useAnimation();
    const controls4 = useAnimation();
    const controls5 = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
        if (inView2) {
            controls2.start('visible');
        }
        if (inView3) {
            controls3.start('visible');
        }
        if (inView4) {
            controls4.start('visible');
        }
        if (inView5) {
            controls5.start('visible');
        }
    }, [controls, controls2, controls3, controls4, controls5,
        inView, inView2, inView3, inView4, inView5])


    useEffect(() => {
        getData();
    }, [getData])

    let path = window.location.pathname;
    let route = path.substring(path.lastIndexOf("/") + 1);
    if (subjects.includes(route)) {
        let element = document.getElementById(route);
        if (element) {
            // Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div className={"Page-root"}>
            <motion.section exit={{ opacity: 0 }}>
                <Menu />
                <section className={isMobile ? "ActivitiesPage-section1-mobile" : "ActivitiesPage-section1"}>
                    <div className={isMobile ? "ActivitiesPage-textBox-right-mobile" : "ActivitiesPage-textBox-right"}>
                        <h1 className={"Typography-red"}>Eerstejaars</h1>
                    </div>
                </section>
            </motion.section>

            <section className={isMobile ? "StatutenPage-section4-mobile" : "StatutenPage-section4"}>
                <div className={isMobile ? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                    <h2 id={"eerstejaarswerking"} className={"Typography-white"}>Eerstejaarswerking</h2>
                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Binnen onze vereniging hebben we verschillende functies, één van deze functies is eerstejaarswerking. Zij focussen zich vooral op de eerstejaars (en studenten die toch nog bij de vereniging willen). Zo komen deze personen langs op de info- en startdagen van onze campussen (campus Gasthuisberg en campus Proximus) om de eerstejaars wat meer uitleg te geven over wie Diana is en wat wij doorheen het jaar voor hen hebben klaarstaan.
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Onze eerstejaarswerking start het jaar steeds met het eerstejaarsweekend, een perfect bonding moment met nieuwe klasgenoten en het Diana bestuur.
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Nog een groot evenement dat de eerstejaarswerking op poten zet, is de meter- en peterwerking. Zeker een aanrader als je tips and tricks voor de examens wil en wat meer wegwijs gemaakt wil worden in Leuven.
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Naast deze twee grote evenementen organiseren ze ook nog andere activiteiten doorheen het jaar, ben je benieuwd naar wat? Blijf Diana dan zeker volgen!
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Heb je vragen omtrent Diana of vragen over je campus, aarzel dan niet om onze eerstejaarswerking of het Diana bestuur aan te spreken in de gangen van je campus. Je kan hen herkennen aan hun Diana trui!
                    </p>

                </div>
                <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                    <motion.img
                        alt="Eerstejaarswerking1"
                        src={"https://images.diana.be/diana_website/events/Eerstejaarswerking1.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref}
                        // animate={controls}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                </div>
                <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                    <motion.img
                        alt="Eerstejaarswerking2"
                        src={"https://images.diana.be/diana_website/events/Eerstejaarswerking2.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref2}
                        // animate={controls2}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                </div>
                <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                    <motion.img
                        alt="Eerstejaarswerking3"
                        src={"https://images.diana.be/diana_website/events/Eerstejaarswerking3.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref3}
                        // animate={controls3}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                </div>
                <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                    <motion.img
                        alt="Eerstejaarswerking4"
                        src={"https://images.diana.be/diana_website/events/Eerstejaarswerking4.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref4}
                        // animate={controls4}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                </div>
                <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                    <motion.img
                        alt="Eerstejaarswerking5"
                        src={"https://images.diana.be/diana_website/events/Eerstejaarswerking5.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                </div>
            </section>

            <section className={isMobile ? "StatutenPage-section3-mobile" : "StatutenPage-section3"}>
                <div className={isMobile ? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                    <h2 id={"eerstejaarsweekend"} className={"Typography-red"}>Eerstejaarsweekend</h2>
                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        Wij organiseren elk schooljaar een eerstejaarsweekend rond eind september begin oktober voor onze nieuwe eerstejaars. Je bent dus ook meer dan welkom als je van richting veranderd bent en nu aan één van onze richtingen die we vertegenwoordigen komt studeren. Specifiek houdt dit volgende richtingen in: de bachelors Biomedische Laboratoriumtechnologie, Chemie, Toegepaste Informatica, Voedings- en Dieetkunde en de graduaten  Biotechnologie, Elektromechanische systemen, Programmeren, Systeem- en Netwerkbeheer en Internet of things. We vertegenwoordigen deze richtingen enkel binnen Leuven, dus Campus Gasthuisberg of Campus Proximus.
                    </p>

                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        Het eerstejaarsweekend wordt georganiseerd om het ijs al wat te breken op verschillende vlakken. Je leert hier mensen van je eigen richting kennen en ook al de andere richtingen die we vertegenwoordigen. Daarnaast krijg je wat tips and tricks mee over je richting en kan je ons vragen stellen. Je leert ons praesidium kennen en zij nemen je al eens mee doorheen de wereld van Diana en hoe wij voor ontspanning zorgen door het jaar.
                    </p>

                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        Tijdens het weekend zelf spelen we ook tal van spellen. Je zal je dus zeker niet vervelen! Er wordt naast de locatie ook eten, drinken, vervoer en een T-shirt voorzien aan een kleine prijs. De eerste avond maken we het gezellig met een kampvuur en een klein feestje. De tweede avond zal onze eerstejaarsweekend cantus plaatsvinden, hier kan je al eens kennismaken met het verloop van een cantus en de mooie zangstemmen van onze leden.
                    </p>
                </div>
                <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                    <motion.img
                        alt="Eerstejaarsweekkend1"
                        src={"https://images.diana.be/diana_website/events/Eerstejaarsweekend1.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                </div>
                <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                    <motion.img
                        alt="Eerstejaarsweekkend2"
                        src={"https://images.diana.be/diana_website/events/Eerstejaarsweekend2.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                </div>
                <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                    <motion.img
                        alt="Eerstejaarsweekkend3"
                        src={"https://images.diana.be/diana_website/events/Eerstejaarsweekend3.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                </div>
                <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                    <motion.img
                        alt="Eerstejaarsweekkend4"
                        src={"https://images.diana.be/diana_website/events/Eerstejaarsweekend4.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                </div>
            </section>

            <section className={isMobile ? "StatutenPage-section4-mobile" : "StatutenPage-section4"}>
                <div className={isMobile ? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                    <h2 id={"meterpeter"} className={"Typography-white"}>Meter -& Peterwerking</h2>
                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Jaarlijks organiseert Diana een meter- en peterwerking, hierbij zoeken we meters/peters die het zien zitten om een eerstejaarsstudent bij te staan waar nodig. We koppelen zo een eerstejaar aan een meter/peter van zijn studierichting. We voorzien doorheen het jaar een aantal activiteiten zodat de meters/peters en eerstejaars elkaar ook leren kennen buiten de campus.
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        De meters/peters kunnen je helpen met inhoudelijke vragen rond bepaalde vakken, alsook eventueel een samenvatting bezorgen. Ze kunnen je wegwijs maken op de campus, want de campus kan soms zo groot lijken wanneer je deze nog niet goed kent. Kort gezegd je meter/peter is er voor de eerstejaar al zeker op vlak van school.
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Natuurlijk hoef je niet elke seconde over school te praten, je kan buiten de campus afspreken om fijne activiteiten samen te doen. Eventueel zelfs met andere meters/peters en eerstejaars. Zo kan je als meter/peter je eerstejaar ook wegwijs maken in Leuven en de beste plaatsjes om te ontspannen meegeven.
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Studenten van Biomedische Laboratoriumtechnologie, die in hun tweede of derde jaar zitten, kunnen door deel te nemen aan dit project een stapje verder raken in hun portfolio. Je hoeft enkel je eerstejaar goed te ondersteunen en deel te nemen aan de activiteiten die wij organiseren (samen met je eerstejaar). Als je het ons vraagt is dit dus zeker een pluspunt!
                    </p>
                </div>
            </section>
            <Footer color={"paper"} />
        </div>
    )
}
export default PraesidiaPage
