import { motion, useAnimation } from "framer-motion";
import Menu from "../components/menu/Menu";
import { useCallback, useEffect, useState } from "react";
import Footer from "../components/footer/Footer";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "@material-ui/core";
import theme from "../theme";
import ImageLeftAnimation from "../components/animations/ImageLeftAnimation";
import { ProseniorObject } from "../objects/ProseniorObject";
import { GetProsenioren } from "../api/api";
import { Link } from "react-router-dom";

const ProseniorenPage = () => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const controls8 = useAnimation();
    const controls9 = useAnimation();
    const controls10 = useAnimation();

    const [ref8, inView8] = useInView();
    const [ref9, inView9] = useInView();
    const [ref10, inView10] = useInView();

    const [prosenioren, setProsenioren] = useState<ProseniorObject[]>();

    const getData = useCallback(async () => {
        try {
            const response = await GetProsenioren()
            setProsenioren(response.data);
        } catch (e) {
        }
    }, []);

    useEffect(() => {
        getData()
        if (inView8) {
            controls8.start('visible');
        }
        if (inView9) {
            controls9.start('visible');
        }
        if (inView10) {
            controls10.start('visible');
        }
    }, [controls8, controls9, controls10, inView8, inView9, inView10]);

    return (
        <div className={"Page-root"}>
            <motion.section exit={{ opacity: 0 }}>
                <Menu />
                <section className={isMobile ? "GeschiedenisPage-section5-mobile" : "GeschiedenisPage-section5"}>
                    <div className={isMobile ? "GeschiedenisPage-textBox-left5-mobile" : "GeschiedenisPage-textBox-left5"}>
                        <h1 className={"Typography-red"}>Praesides van Diana</h1>

                        <p className={"Typography-red"}>
                            Hier zie je een chronologische opsomming van de praesides (praesessen) van Diana,
                            van het ontstaan in 1960 tot het heden.

                        </p>
                        <p className={"Typography-red Typography-alinea"}>
                            Zoals je echter zal opmerken zijn er in de loop der tijden enkele namen verloren gegaan.
                            Indien je op de hoogte bent van een praeses die op onderstaande lijst ontbreekt vragen we je contact met ons op te nemen.
                        </p>
                        <div className={"GeschiedenisPage-divider"} />
                        <motion.img
                            alt="Oud praesides"
                            src={"https://images.diana.be/diana_website/OudPraeses.jpg"}
                            className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                            ref={ref8}
                            animate={controls8}
                            initial="hidden"
                            variants={ImageLeftAnimation}
                        />
                        <p className={isMobile ? "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right-mobile" : "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right"}>Van links naar rechts: Jos Van Reempts, Jef Bessemans, Betty Verdoodt, Jan Francx, Paul Boesmans</p>
                        <div className={"GeschiedenisPage-divider"} />
                        <motion.img
                            alt="Oud praesides 2"
                            src={"https://images.diana.be/diana_website/Oudleden-Cantus.jpg\n"}
                            className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                            ref={ref9}
                            animate={controls9}
                            initial="hidden"
                            variants={ImageLeftAnimation}
                        />
                        <p className={isMobile ? "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right-mobile" : "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right"}>Een aantal van onze pro-senioren op onze Oud-leden cantus 2018</p>

                        <div className={"GeschiedenisPage-divider"} />
                        <motion.img
                            alt="Oud praesides 3"
                            src={"https://images.diana.be/diana_website/Praesides-Lustrum.jpg"}
                            className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                            ref={ref10}
                            animate={controls10}
                            initial="hidden"
                            variants={ImageLeftAnimation}
                        />
                        <p className={isMobile ? "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right-mobile" : "Typography-black Typography-italic Typography-bold GeschiedenisPage-image-subText-right"}>Aanwezige pro-senioren op het lustrum event 2020 in volgorde</p>

                    </div>
                    <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}>
                        <div className={"GeschiedenisPage-section5-praesides"}>
                            <h2 className={"Typography-black"}>Onze praesides: </h2>
                            <ul className={"Typography-black Typography-bold Section3-ul"}>
                                {prosenioren?.map(prosenior => (
                                    <Link to={`/alumia/${prosenior.jaar}`}><li key={prosenior.jaar}><p>{prosenior.jaar} {prosenior.naam}</p></li></Link>

                                ))}
                            </ul>
                        </div>


                    </div>
                </section>
            </motion.section>
            <Footer color={"primary"} />
        </div>
    );
};

export default ProseniorenPage;