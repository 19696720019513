import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import EventIcon from '@material-ui/icons/Event';
import SchoolIcon from '@material-ui/icons/School';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import GroupIcon from '@material-ui/icons/Group';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import HistoryIcon from '@material-ui/icons/History';
import DescriptionIcon from '@material-ui/icons/Description';
import RoomIcon from '@material-ui/icons/Room';
import GavelIcon from '@material-ui/icons/Gavel';
import StorageIcon from '@material-ui/icons/Storage';
import CommentIcon from '@material-ui/icons/Comment';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import LocalOffer from '@material-ui/icons/LocalOffer';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Waves from '@material-ui/icons/Waves';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import {
    Celebration, ContactPage,
    Diversity3,
    Grade,
    Hotel,
    Nightlight,
    PeopleOutline,
    SportsBar,
    TableRestaurant
} from "@mui/icons-material";

interface Props {
    name: string;
}

const MenuItemMobileIcon = ({ name }: Props) => {
    return (
        <>
            {name === "Home" &&
                <HomeIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Over Diana" &&
                <InfoIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Studies" &&
                <SchoolIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Contact" &&
                <QuestionAnswerIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Campus" &&
                <RoomIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Huidig Praesidium" &&
                <GroupIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Kringlied" &&
                <MusicNoteIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Geschiedenis" &&
                <HistoryIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Praesides" &&
                <RecentActorsIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Examenwiki" &&
                <DescriptionIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Examenrooster" &&
                <EventIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Reglementen" &&
                <GavelIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "GDPR" &&
                <StorageIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Intern reglement" &&
                <CommentIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Statuten" &&
                <HowToVoteIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Lidkaarten" &&
                <LoyaltyIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Sponsors" &&
                <LocalOffer className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Webshop" &&
                <ShoppingCart className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Activiteiten" &&
                <EventIcon className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Strandcantus" &&
                <Waves className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Dianalympische Cantus" &&
                <TableRestaurant className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Lustrum" &&
                <Grade className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "NVDJ" &&
                <Nightlight className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Sweet Tropical" &&
                <Celebration className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Jägerbomb Record" &&
                <SportsBar className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Eerstejaars" &&
                <Diversity3 className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Eerstejaarswerking" &&
                <ContactPage className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Eerstejaars Weekend" &&
                <Hotel className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
            {name === "Meter -& Peterwerking" &&
                <PeopleOutline className={"MenuItemMobileIcon-root"} fontSize={"inherit"} />
            }
        </>
    )
}
export default MenuItemMobileIcon