import axios from 'axios';
import { API_BASE_URL } from '../config';

const customAxios = axios.create({
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, HEAD, OPTIONS"
    },
    baseURL: API_BASE_URL
});

export default customAxios;
