import { AnimatePresence, AnimateSharedLayout, motion, useAnimation } from 'framer-motion';
import Menu from '../components/menu/Menu';
import React, { Props, useCallback, useEffect, useState } from 'react';
import { GetCurrentYear, GetPraesidia, GetPraesidiaFromYear } from '../api/api';
import { PraesidiaObject } from '../objects/PraesidiaObject';
import { useMediaQuery } from "@material-ui/core";
import theme from "../theme";
import { useInView } from "react-intersection-observer";
import Footer from "../components/footer/Footer";
import { useParams } from 'react-router-dom';
import ImageLeftAnimation from '../components/animations/ImageLeftAnimation';

/**
 * This list is used for the activities page internal linking
 */
const activities = [
    "activiteiten",
    "strandcantus",
    "dianalympische",
    "lustrum",
    "nvdj",
    "sweettropical",
    "jagerbombrecord"
]

const PraesidiaPage = ({ props }: any) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [praesidia, setPraesidia] = useState<PraesidiaObject[]>();
    const [selectedBestuurslid, setSelectedBestuurslid] = useState<PraesidiaObject | null>(null);
    const [currentYear, setCurrentYear] = useState("")
    const getData = useCallback(async () => {
        try {

            const response = await GetPraesidia()
            setPraesidia(response.data);
            const response2 = await GetCurrentYear()
            setCurrentYear(response2.data)


        } catch (e) {
        }
    }, []);

    const [ref, inView] = useInView();
    const controls = useAnimation();

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    })


    useEffect(() => {
        getData();
    }, [getData])

    let path = window.location.pathname;
    let route = path.substring(path.lastIndexOf("/") + 1);
    if (activities.includes(route)) {
        let element = document.getElementById(route);
        if (element) {
            // Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div className={"Page-root"}>
            <motion.section exit={{ opacity: 0 }}>
                <Menu />
                <section className={isMobile ? "ActivitiesPage-section1-mobile" : "ActivitiesPage-section1"}>
                    <div className={isMobile ? "ActivitiesPage-textBox-right-mobile" : "ActivitiesPage-textBox-right"}>
                        <h1 className={"Typography-red"}>Activiteiten</h1>
                    </div>
                </section>
            </motion.section>

            <section className={isMobile ? "StatutenPage-section4-mobile" : "StatutenPage-section4"}>
                <div className={isMobile ? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                    <h2 id={"strandcantus"} className={"Typography-white"}>Strandcantus</h2>
                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Naar jaarlijkse gewoonte trekt Diana elk jaar een weekend naar zee. Deze traditie is ontstaan tijdens het bestuursjaar van 2006-2007. De laatste jaren vond de strandcantus plaats tijdens het tweede weekend van september voorheen was dit in juli. Niet alleen de Dianen zijn hier voltallig aanwezig, maar ook de andere OKeR kringen zijn terug te vinden op deze cantus.
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Zaterdag doorheen de dag komt iedereen aan op de camping en worden de tenten opgezet. We maken het gezellig door campingstoeltjes mee te brengen en ons in een grote cirkel te plaatsen. We spelen spelletjes terwijl de muziek door de boxen weerklinkt. Iedereen zorgt voor zijn eigen snacks en drinken. Dit alles geeft echte kampeer vibes.
                    </p>

                    {/* <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}> */}
                    <motion.img
                        alt="Strandcantus1"
                        src={"https://images.diana.be/diana_website/events/Strandcantus1.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                    {/* </div> */}

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Tijdens het weekend ben je vrij om te gaan en staan waar je wilt, je kan met een groepje dus eens naar het strand gaan doorheen de dag, maar je kan ook gezellig op de camping blijven. Je voorziet in de avond en ochtend dan ook je eigen eten.
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Zaterdagavond vertrekken we naar de Duinen om ons daar in het zand te zetten voor de geweldige strandcantus. Tijdens deze cantus wordt er veel gelachen, maar ook gezongen en geknuffeld (om de koude te trotseren). Deze cantus is er dus zeker eentje die de moeite waard is om mee te doen!
                    </p>
                    {/* <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}> */}
                    <motion.img
                        alt="Strandcantus2"
                        src={"https://images.diana.be/diana_website/events/Strandcantus2.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                    {/* </div> */}
                </div>
            </section>

            <section className={isMobile ? "StatutenPage-section3-mobile" : "StatutenPage-section3"}>
                <div className={isMobile ? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                    <h2 id={"dianalympische"} className={"Typography-red"}>Dianalympische Cantus</h2>
                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        Op het einde van het academiejaar van 2015-2016, kwam de cantor van dat jaar, Gunther Piron af met het concept van de Dianalmypische cantus.
                    </p>

                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        In het academiejaar van 2016-2017 wou de huidige praeses deze cantus graag voorzitten met de grondlegger van dit concept. Dit geschiedde en zo heeft iedere opvolgende praesidia deze traditie verder gezet. In het jaar 2021-2022 vierde de Dianalypische cantus zijn vijfjarig bestaan. Dit bestaan werd bezegeld met het Dianalympische lied dat geschreven werd door Gunther Piron en Yassine Quisquater. Sindsdien geeft dit lied de start van de Dianalympsiche cantus weer.
                    </p>

                    {/* <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}> */}
                    <motion.img
                        // ref={ref}
                        // animate={controls}
                        initial="hidden"
                        // variants={ImageLeftAnimation}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        alt={"De Dianalympische Cantus"}
                        src={"https://images.diana.be/diana_website/DianalympischeCantus.png"} />
                    {/* </div> */}

                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        Het thema van de Dianalympische cantus is een jaarlijkse competitie tussen de Diana richtingen en Artemis. De winnaar mag zich een jaar lang Dianalympische kampioen noemen! De leden van de seniorentafel ontfermen zich over het leiden van de cantus en de competitie.
                    </p>

                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        De competitie bestaat uit een aantal rondes. Om te kunnen zegevieren zal men beproefd worden op verscheidene vlakken zoals studentikoos, improvisatie, inspiratie en de historiek van Diana.
                    </p>
                </div>
            </section >

            <section className={isMobile ? "StatutenPage-section4-mobile" : "StatutenPage-section4"}>
                <div className={isMobile ? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                    <h2 id={"lustrum"} className={"Typography-white"}>Lustrum</h2>
                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Het woord "lustrum" komt oorspronkelijk uit het Latijn, waar het verwees naar een reinigingsoffer, specifiek een offer dat door bepaalde ambtenaren werd gebracht na hun ambtstermijn van vijf jaar. Van daaruit kreeg het woord de overdrachtelijke betekenis van ‘vijfjarige periode’, die ook in het Nederlands werd overgenomen.
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Om de 5 jaar houdt Diana dus een lustrumviering, hier wordt het bestaan van de kring gevierd. In het jaar van lustrum houden we tijdens het praesidiumjaar een feestweek. Tijdens deze feestweek worden er verschillende activiteiten georganiseerd zoals een speciale TD, quiz, facbartocht… Op het einde van de week vieren we met Diana dan een groot feest, wat we de lustrumviering noemen. Tijdens deze viering houden we een receptie, lezingen, korte cantus, is er veel eten en drinken en om af te sluiten een afterparty. Allle Dianen zijn hier op uitgenodigd. Een lustrum is dus iets heel speciaals en het is altijd fijn om oud-Dianen terug te zien!
                    </p>

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Een lustrumjaar kan je herkennen aan de lauwerkrans die op de merchandising en banners geplaatst wordt. Vaak wordt er ook een jaartal op de krans gezet, dit jaartal toont aan hoe lang de kring al bestaat.
                    </p>
                </div>
            </section>

            <section className={isMobile ? "StatutenPage-section3-mobile" : "StatutenPage-section3"}>
                <div className={isMobile ? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                    <h2 id={"nvdj"} className={"Typography-red"}>Nacht Van De Jacht</h2>

                    {/* <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}> */}
                    <motion.img
                        alt="NVDJ1"
                        src={"https://images.diana.be/diana_website/events/NVDJ1.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                    {/* </div> */}

                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        Deze TD is een verwijzing naar de Godin Diana, godin van de nacht en de jacht. De TD valt in het eerste semester, perfect om de jacht op je prooi te openen. Als wij aan jagen denken, denken wij aan een hert. Vandaar dat het hert vaak terugkomt in onze decoratie en op de voorbije banners.
                    </p>

                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        Bij Nacht van de Jacht hoort natuurlijk een speciaal concept. Bij aankomst krijgt iedereen een lichtgevend bandje met de gewenste kleur: groen = single, oranje = complicated en rood = taken. De avond is op dat moment nog jong, maar de nacht zal lang zijn!
                    </p>

                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        Op deze TD komen onze beste DJ’s langs, zij zullen je voorzien van geweldige dansplaatjes. Je benen goed los schudden op Nacht van de Jacht kan dus zeker!
                    </p>

                    <p className={"Typography-red Typography-bold Typography-alinea"}>
                        Nacht van de Jacht komt uit de verre geschiedenis van Diana en blijft nog steeds meegaan. Studenten zijn hier dan ook talrijk aanwezig!
                    </p>
                    {/* <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}> */}
                    <motion.img
                        alt="NVDJ2"
                        src={"https://images.diana.be/diana_website/events/NVDJ2.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                    {/* </div> */}
                </div>
            </section>

            <section className={isMobile ? "StatutenPage-section4-mobile" : "StatutenPage-section4"}>
                <div className={isMobile ? "StatutenPage-textBox-right-mobile" : "StatutenPage-textBox-right"}>
                    <h2 id={"jagerbombrecord"} className={"Typography-white"}>Jägerbomb recordpoging</h2>
                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Wanneer de meeste mensen aan Diana denken, denken ze automatisch aan Jägerbomb. Jägerbomb wordt gemaakt door een longdrinkglas 1/5de te vullen met Red Bull en er een shot Jägermeister aan toe te voegen. Natuurlijk maken we het bij Diana wat spannender, zo maakte we vroeger regelmatig een Jägertrein op de toog van de Maxim’O. Er worden dan glazen op een rij gezet, net zoals domino, en op de rand van het longdrinkglas zet men een shotje Jäger. Wanneer men dan tegen het eerste shotje Jäger duwt krijg je een domino-effect, het shotje zelf valt in het glas en zo kan je na de Jägertrein genieten van een glas Jägerbomb.
                    </p>

                    {/* <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}> */}
                    <motion.img
                        alt="Jager2"
                        src={"https://images.diana.be/diana_website/events/Jager2.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                    {/* </div> */}

                    <p className={"Typography-white Typography-bold Typography-alinea"}>
                        Hierdoor krijg je de sfeer op een facavond er zeker in, maar toch vond Diana dit nog niet goed genoeg en wouden ze graag een stapje verder gaan. De Dianen besloten in 2016 om het Belgisch record Jägerbombs te proberen verbreken. Het record stond op 250 en Diana verbrak dit moeiteloos door het record op 490 te zetten! In 2018 hebben we dit opnieuw geprobeerd, toen gingen we voor meer dan 1000 glazen, maar in de helft viel de trein stil waardoor we het spijtig genoeg niet gehaald hebben.
                        Hierdoor haalde Diana ook al een paar keer de kranten en werd onze vereniging in de bloemetjes gezet.
                    </p>

                    {/* <div className={isMobile ? "GeschiedenisPage-imageBox-right-mobile" : "GeschiedenisPage-imageBox-right"}> */}
                    <motion.img
                        alt="Jager1"
                        src={"https://images.diana.be/diana_website/events/Jager1.jpg"}
                        className={isMobile ? "GeschiedenisPage-image-mobile" : "GeschiedenisPage-image"}
                        // ref={ref5}
                        // animate={controls5}
                        initial="hidden"
                    // variants={ImageRightAnimation}
                    />
                    {/* </div> */}

                    <a href={"https://www.facebook.com/dianaleuven/videos/10154440496814270"}>Recordpoging 2016</a>
                    <a href={"https://www.facebook.com/dianaleuven/videos/373409993228237"}>Recordpoging 2018</a>
                    <a href="https://www.hln.be/het-leukste-van-het-web/video-studenten-stellen-belgisch-record-jagerbomb-niet-scherper-maar-beelden-zijn-geweldig~vadbdccc1/">Artikel HLN</a>
                </div>
            </section>
            <Footer color={"paper"} />
        </div >
    )
}
export default PraesidiaPage
